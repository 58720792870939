var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Firmendaten")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Firmenname*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Firmenname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","id":"company_name","placeholder":"Firmenname"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Inhaber*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Inhaber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Inhaber"},model:{value:(_vm.company.owner),callback:function ($$v) {_vm.$set(_vm.company, "owner", $$v)},expression:"company.owner"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Ansprechpartner*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ansprechpartner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Ansprechpartner"},model:{value:(_vm.company.contact_person),callback:function ($$v) {_vm.$set(_vm.company, "contact_person", $$v)},expression:"company.contact_person"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Handy*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Handy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"Handy"},model:{value:(_vm.company.mobile),callback:function ($$v) {_vm.$set(_vm.company, "mobile", $$v)},expression:"company.mobile"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Telefon"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Telefon"},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Fax"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Fax"},model:{value:(_vm.company.fax),callback:function ($$v) {_vm.$set(_vm.company, "fax", $$v)},expression:"company.fax"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"E-Mail*"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"E-Mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","placeholder":"E-Mail"},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Unternehmensform"}},[_c('b-form-select',{attrs:{"text-field":"name","value-field":"id","options":_vm.company_forms},model:{value:(_vm.company.company_form.id),callback:function ($$v) {_vm.$set(_vm.company.company_form, "id", $$v)},expression:"company.company_form.id"}})],1)],1)],1),_c('br'),_c('h4',[_vm._v("Adresse")]),_c('b-row',{staticClass:"mb-3 mb-lg-0"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Straße / Hnr.*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Straße / Hnr."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Straße / Hnr."},model:{value:(_vm.company.address.street),callback:function ($$v) {_vm.$set(_vm.company.address, "street", $$v)},expression:"company.address.street"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Ort*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ort"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Ort"},model:{value:(_vm.company.address.city),callback:function ($$v) {_vm.$set(_vm.company.address, "city", $$v)},expression:"company.address.city"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Plz*"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|digits:5","name":"Plz"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","maxlength":"5","placeholder":"Plz"},model:{value:(_vm.company.address.zip),callback:function ($$v) {_vm.$set(_vm.company.address, "zip", $$v)},expression:"company.address.zip"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('br'),_c('h4',[_vm._v("Lieferadresse")]),_c('b-row',{staticClass:"mb-3 mb-lg-0"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name*"}},[_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Name"},model:{value:(_vm.company.delivery_address.name),callback:function ($$v) {_vm.$set(_vm.company.delivery_address, "name", $$v)},expression:"company.delivery_address.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3 mb-lg-0"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Straße / Hnr.*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Straße / Hnr."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Straße / Hnr."},model:{value:(_vm.company.delivery_address.street),callback:function ($$v) {_vm.$set(_vm.company.delivery_address, "street", $$v)},expression:"company.delivery_address.street"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Ort*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ort"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Ort"},model:{value:(_vm.company.delivery_address.city),callback:function ($$v) {_vm.$set(_vm.company.delivery_address, "city", $$v)},expression:"company.delivery_address.city"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Plz*"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|digits:5","name":"Plz"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","maxlength":"5","placeholder":"Plz"},model:{value:(_vm.company.delivery_address.zip),callback:function ($$v) {_vm.$set(_vm.company.delivery_address, "zip", $$v)},expression:"company.delivery_address.zip"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),_c('br'),_c('h4',[_vm._v("Bankdaten")]),_c('b-row',{staticClass:"mb-3 mb-lg-0"},[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Kreditinstitut*"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Kreditinstitut"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","placeholder":"Kreditinstitut"},model:{value:(_vm.company.bank_details.name),callback:function ($$v) {_vm.$set(_vm.company.bank_details, "name", $$v)},expression:"company.bank_details.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"IBAN (ohne Leerzeichen)*"}},[_c('validation-provider',{attrs:{"rules":"required|length:22","name":"IBAN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","maxlength":"22","placeholder":"IBAN"},model:{value:(_vm.company.bank_details.iban),callback:function ($$v) {_vm.$set(_vm.company.bank_details, "iban", $$v)},expression:"company.bank_details.iban"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('b-form-group',{attrs:{"label":"BIC*"}},[_c('validation-provider',{attrs:{"rules":"required|length:11","name":"BIC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{'is-invalid': errors.length },attrs:{"type":"text","maxlength":"11","placeholder":"BIC"},model:{value:(_vm.company.bank_details.bic),callback:function ($$v) {_vm.$set(_vm.company.bank_details, "bic", $$v)},expression:"company.bank_details.bic"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors.length),expression:"errors.length"}],staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1),(_vm.company.bank_details.name !== _vm.original_company.bank_details.name ||
                      _vm.company.bank_details.iban !== _vm.original_company.bank_details.iban ||
                      _vm.company.bank_details.bic !== _vm.original_company.bank_details.bic)?[_c('span',{staticClass:"invalid-feedback"},[_c('i',{staticClass:"fa fa-close"}),_vm._v(" Achtung: Änderungen der Bankdaten werden erst von uns geprüft und später gespeichert. ")])]:_vm._e(),_c('p',{staticClass:"text-right mb-0"},[_vm._v("mit * gekennzeichnete Felder sind Pflichtfelder")])],2),_c('br'),_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.$router.go()}}},[_vm._v("Abbrechen")]),_c('div',{staticClass:"primary-action"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid,"id":"save","size":"lg","pill":""},on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Speichern ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }