<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'

export default {
  name: "Details",
  data: function () {
    return {
      company_forms: [],
      original_company: {
        bank_details: {},
      },
      company: {
        address: {},
        bank_details: {},
        delivery_address: {name: ''},
        billing_address: {},
        company_form: {}
      },
    }
  },
  mounted: function(){
    this.$store.dispatch('setIntroJsEnabled', false);
  },
  destroyed: function(){
    this.$store.dispatch('setIntroJsEnabled', true);
  },
  created: function(){
    let self = this;
    Http.request('GET', '/user/company').then((company) => {
      if(!company.data.address){
        company.data.address = {};
      }
      if(!company.data.delivery_address){
        company.data.delivery_address = {};
      }
      if(!company.data.billing_address){
        company.data.billing_address = {};
      }
      if(!company.data.bank_details){
        company.data.bank_details = {};
      }
      if(!company.data.company_form){
        company.data.company_form = {};
      }

      self.company = company.data;
      self.original_company = JSON.parse(JSON.stringify(company.data));
    });
    Http.request('GET', '/user/company/forms').then((forms) => {
      self.company_forms = forms.data;
    });
  },
  methods: {
    save(){
      let self = this;
      Http.request('POST', '/user/company', self.company).then((company) => {
        self.$bvToast.toast('Die Änderungen wurden gespeichert', {
          title: 'Gespeichert',
          variant: 'success',
          autoHideDelay: 5000
        });
        if(self.company.bank_details.name !== self.original_company.bank_details.name ||
          self.company.bank_details.iban !== self.original_company.bank_details.iban ||
          self.company.bank_details.bic !== self.original_company.bank_details.bic){
          self.$bvToast.toast('Änderungen der Bankdaten werden erst von uns geprüft und später gespeichert.', {
            title: 'Achtung',
            variant: 'warning',
            autoHideDelay: 5000
          });
        }
      });
    },
  }
}
</script>

<style scoped></style>
